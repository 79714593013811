<template>
  <CentralizeLayout>
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <b-col lg="4">
      <div class="text-center mb-4">
        <logo name="logo" />
      </div>
      <b-alert
        class="mt-3"
        :show="hasAuthenticationStatus"
        :variant="'danger'"
        >{{ $t(`errorRegister.${errorCode}`) }}</b-alert
      >
      <b-card class="p-2 auth-card">
        <h5 class="mb-4">{{ $t("createNewAccount") }}</h5>
        <b-form class="mt-3" novalidate @submit.prevent="onSubmit">
          <b-form-group :label="$t('username')">
            <b-input-group>
              <b-form-input
                v-model.trim.lazy="$v.form.username.$model"
                type="email"
                required
                :state="validateState('email')"
                :placeholder="$t('placeholderUsername')"
                :disabled="isLoading"
                @blur="$v.form.username.$touch()"
              />
              <b-form-invalid-feedback>{{
                $t("usernameErrMsg")
              }}</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('email')">
            <b-input-group>
              <b-form-input
                v-model.trim.lazy="$v.form.email.$model"
                type="email"
                required
                :state="validateState('email')"
                :placeholder="'john.doe@gmail.com'"
                :disabled="isLoading"
                @blur="$v.form.email.$touch()"
              />
              <b-form-invalid-feedback>{{
                $t("emailErrMsg")
              }}</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('password')" class="mt-3 position-relative">
            <b-form-input
              v-model.trim.lazy="$v.form.password.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :placeholder="$t('placeholderPassword')"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("passwordErrMsg")
            }}</b-form-invalid-feedback>
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-form-group
            :label="$t('reenterPassword')"
            class="mt-3 position-relative"
          >
            <b-form-input
              v-model.trim.lazy="$v.form.reenterPassword.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
            />
            <b-form-invalid-feedback
              :state="$v.form.reenterPassword.sameAsPassword"
              >{{ $t("reenterPasswordErrMsg") }}</b-form-invalid-feedback
            >
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-form-checkbox v-model="form.terms" class="mt-3" required>
            {{ $t("agreeTermAndCondition") }}
          </b-form-checkbox>
          <b-form-invalid-feedback :state="validateState('terms')">{{
            $t("checkboxErrMsg")
          }}</b-form-invalid-feedback>
          <b-button
            class="mt-3"
            block
            :disabled="isLoading || $v.form.$anyError"
            type="submit"
            variant="primary"
          >
            <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
            {{ $t("createNewAccount") }}
          </b-button>
        </b-form>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="link"
            :to="{ name: 'Login', query: { lang: this.$route.query.lang } }"
          >
            {{ $t("returnLogin") }}
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col lg="4" class="text-footer">
      <div>
        <span class="mr-2">{{ $t("contact") }}</span>
        <span class="mr-2">{{ $t("legal") }}</span>
        <span>{{ $t("privacy") }}</span>
      </div>

      <div class="ml-auto">
        <b-dropdown class="language-dropdown" dropup no-caret right>
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <icon class="mr-2" name="language" />
              <span v-if="$root.$i18n.locale === 'en'" style="font-size: 12px;"
                >English</span
              >
              <span v-if="$root.$i18n.locale === 'id'" style="font-size: 12px;"
                >Indonesia</span
              >
              <b-icon
                class="caret ml-1"
                icon="caret-up-fill"
                font-scale="0.85"
              />
            </div>
          </template>
          <b-dropdown-item @click="setLanguageEn">English</b-dropdown-item>
          <b-dropdown-item @click="setLanguageId"
            >Bahasa Indonesia</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "createNewAccount": "Create new account",
    "username": "Username",
    "usernameErrMsg": "Username must be filled",
    "placeholderUsername": "What is your name?",
    "email": "Email",
    "emailErrMsg": "Choose a valid email address.",
    "password": "Password",
    "placeholderPassword": "At least 8 characters",
    "reenterPassword": "Re-enter password",
    "passwordErrMsg": "Choose a password between 8-12 characters, with at least 1 uppercase, 1 lowercase, 1 number and 1 special character.",
    "reenterPasswordErrMsg": "Password didn't match.",
    "checkboxErrMsg": "You need to accept our term & condition to use Survein product",
    "passwordPlaceholder": "At least 8 character",
    "returnLogin": "Return to log in",
    "errorRegister": {
      "UsernameExistsException": "An account with the given email already exists.",
      "unspecific": "Failed to sign up."
    },
    "agreeTermAndCondition": "I’m agree to Survein’s term & condition",
    "confirmationCodeSent": "Code verification has been sent to your email",
    "contact": "Contact",
    "legal": "Legal",
    "privacy": "Privacy & terms",
    "languageSaved": "Language is saved"
  },
  "id": {
    "createNewAccount": "Buat akun baru",
    "email": "Email",
    "username": "Nama pengguna",
    "usernameErrMsg": "Nama pengguna harus diisi",
    "placeholderUsername": "Siapa nama anda?",
    "emailErrMsg": "Pilih alamat email yang valid.",
    "password": "Kata sandi",
    "placeholderPassword": "Setidaknya 8 karakter",
    "reenterPassword": "Masukkan ulang kata sandi",
    "passwordErrMsg": "Pilih kata sandi antara 8-12 karakter, dengan minimal 1 huruf besar, 1 huruf kecil, 1 angka dan 1 karakter khusus",
    "reenterPasswordErrMsg": "Kata sandi tidak sama.",
    "checkboxErrMsg": "Anda harus menerima syarat & ketentuan kami untuk menggunakan produk Survein",
    "passwordPlaceholder": "Setidaknya 8 karakter",
    "returnLogin": "Kembali untuk masuk",
    "errorRegister": {
      "UsernameExistsException": "Akun dengan email yang diberikan sudah ada.",
      "unspecific": "Gagal mendaftar."
    },
    "agreeTermAndCondition": "Saya setuju dengan syarat & ketentuan Survein",
    "confirmationCodeSent": "Kode verifikasi telah dikirim ke email Anda",
    "contact": "Kontak",
    "legal": "Hukum",
    "privacy": "Privasi & persyaratan",
    "languageSaved": "Bahasa disimpan"
  }
}
</i18n>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

const errorCodes = ["UsernameExistsException"];
const fallbackErrorCode = "unspecific";

export default {
  components: {
    CentralizeLayout
  },
  props: {
    lang: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        username: "",
        email: "",
        password: "",
        reenterPassword: "",
        terms: false
      },
      showPassword: false
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationStatus", "authenticationStatus"]),
    errorCode() {
      return errorCodes.includes(this.authenticationStatus.code)
        ? this.authenticationStatus.code
        : fallbackErrorCode;
    }
  },
  validations: {
    form: {
      username: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        password: v =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,12}$/.test(v)
      },
      reenterPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
      terms: {
        required,
        terms: value => value === true
      }
    }
  },
  mounted() {
    this.clearAuthenticationStatus();

    if (this.lang === "en") {
      this.$root.$i18n.locale = "en";
    } else {
      this.$root.$i18n.locale = "id";
    }
  },
  methods: {
    ...mapActions("auth", [
      "clearAuthenticationStatus",
      "signUp",
      "setTemp",
      "setUsername"
    ]),
    ...mapActions("profile", ["setLanguage", "createProfile"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    setLanguageEn() {
      this.$router.replace({ query: { lang: "en" } });
    },
    setLanguageId() {
      this.$router.replace({ query: { lang: "id" } });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      await this.signUp({
        username: this.form.email,
        password: this.form.password,
        attributes: {
          nickname: this.form.username
        }
      });
      this.setTemp(this.form.password);
      this.setUsername(this.form.username);
      if (!this.hasAuthenticationStatus) {
        this.$router.push({
          name: "ConfirmSignUp",
          params: { email: this.form.email }
        });

        this.$toast.success(
          `${this.$t("confirmationCodeSent")} ${this.form.email}`,
          {
            timeout: 3500
          }
        );
      }
      this.isLoading = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.query.lang === "id") {
        this.$root.$i18n.locale = "id";
      } else {
        this.$root.$i18n.locale = "en";
      }
    }
  }
};
</script>
